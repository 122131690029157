export const MRCOOL = 'MRCOOL®';
export const BASE_URL: string = process.env.API_ENDPOINT ?? '';
export const MAP_PREFIX = '/geocode/json';
export const SELECTOR_FORM_PEFIX = '/configurator/by-model-number';
export const API_KEY = process.env.API_KEY_GOOGLEMAPS as string;
export const GOOGLE_MAPS_API = 'https://maps.googleapis.com/maps/api';
export const NAV_SLUG = '/header-navigation';
export const NAV_PARAMS = {
  populate: {
    list: {
      populate: {
        products: {
          populate: '*',
        },
      },
    },
  },
};
export const HOME_SLUG = '/page/homepage';
export const HOME_PARAMS = {
  populate: {
    sections: { populate: '*' },
    product_category_showcase: {
      populate: {
        thumbnail: '*',
        hero: '*',
        products: {
          populate: ['thumbnail'],
        },
      },
    },
    product_showcase: {
      populate: ['thumbnail'],
    },
  },
};
export const DIY_SLUG = 'diy-4th-generation';
export const DIY_PARAMS = {
  populate: {
    product_categories: '*',
    thumbnail: '*',
    galleries: '*',
    files: '*',
    product_reviews: '*',
    support_page: '*',
    features: {
      populate: ['list.assets'],
    },
    assets: {
      populate: '*',
    },
    product_variants: {
      populate: ['stores', 'stores.image'],
    },
    compatibility_list: {
      populate: ['images'],
    },
  },
};
export const FOOTER_LINKS = [
  { href: 'https://support.mrcool.com', text: 'Support', target: '_blank' },
  { href: '/our-story', text: 'Our Story' },
  {
    href: 'https://support.mrcool.com/hc/en-us/requests/new',
    text: 'Contact Us',
    target: '_blank',
  },
  {
    href: '/support/dealer-signup',
    text: 'Become a Dealer',
    target: '_blank',
  },
  { href: '/locations', text: 'Locations' },
  { href: '/rebates', text: 'Rebate Center' },
  {
    href: '/support/become-influencer',
    text: 'Become an Influencer',
    target: '_blank',
  },
  { href: '/blog', text: 'Blog' },
  { href: '/system-selector', text: 'System Selector (beta)' },
  { href: '/warranty-information', text: 'Warranty Information' },
  { href: '/warranty-registration', text: 'Warranty Registration' },
  { href: '/intelectual-property/patents', text: 'Patents' },
  { href: '/intelectual-property/trademarks', text: 'Trademarks' },
  { href: 'https://portal.mrcool.com', text: 'Partner Center' },
  {
    href: 'https://www.mrcool.com/wp-content/dox_repo/mc-cat-en-01.pdf',
    text: 'Catalog',
    target: '_blank',
  },
];
export const FOOTER_SOCIAL_LINKS: Map<string, { href: string; icon: string }> =
  new Map<string, { href: string; icon: string }>([
    [
      'facebook',
      { href: 'https://www.facebook.com/MRCOOLcomfort', icon: 'FaFacebookF' },
    ],
    [
      'instagram',
      { href: 'https://www.instagram.com/MRCOOLcomfort', icon: 'FaInstagram' },
    ],
    [
      'youTube',
      { href: 'https://www.youtube.com/@MrcoolComfort', icon: 'FaYoutube' },
    ],
    ['tiktok', { href: 'https://www.tiktok.com/@mrcooldiy', icon: 'FaTiktok' }],
    [
      'twitter',
      { href: 'https://twitter.com/mrcoolcomfort', icon: 'FaXTwitter' },
    ],
    [
      'pinterest',
      { href: 'https://www.pinterest.com/mrcoolcomfort', icon: 'FaPinterest' },
    ],
  ]);
export const FOOTER_TEXT = {
  copyright: `Copyright © ${new Date().getFullYear()} ${MRCOOL}. All Rights Reserved.`,
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms & Conditions',
};
export const BUTTON_TEXT = {
  shopNow: {
    btnText: 'Where to buy',
  },
};
export const REBATES_FOOTER = {
  'diy-4th-generation': {
    colorButton: 'primary',
    classNameButton: 'btn-diy',
  },
  'geo-cool-inverter': {
    colorButton: 'dark',
    classNameButton:
      'border-[#476E30] bg-[#476E30] hover:bg-[#476E30] hover:border-[#476E30] text-white',
  },
  'universal-series': {
    colorButton: 'primary',
    classNameButton: 'btn-universal',
  },
};
export const MAP_RADIAL_OPTIONS = [
  {
    label: '5 mi',
    value: 5,
    zoom: 12,
  },
  {
    label: '10 mi',
    value: 10,
    zoom: 11,
  },
  {
    label: '15 mi',
    value: 15,
    zoom: 10.5,
  },
  {
    label: '20 mi',
    value: 20,
    zoom: 10,
  },
  {
    label: '25 mi',
    value: 25,
    zoom: 9.5,
  },
  {
    label: '30 mi',
    value: 30,
    zoom: 9,
  },
  {
    label: '35 mi',
    value: 35,
    zoom: 8.8,
  },
  {
    label: '40 mi',
    value: 40,
    zoom: 8.7,
  },
  {
    label: '45 mi',
    value: 45,
    zoom: 8.5,
  },
  {
    label: '50 mi',
    value: 50,
    zoom: 8.4,
  },
  {
    label: '100 mi',
    value: 100,
    zoom: 7.3,
  },
];
export const MAP_TAG_BUTTONS = {
  franchise: 'Contractor',
  installer: 'Installer',
  online: 'Online Retailer',
  retail: 'Retail Store',
  dealer: 'Dealer',
  inStock: 'In Stock',
};
export const MAP_PARAMS = {
  populate: {
    store_types: {
      fields: ['name'],
    },
  },
  pagination: {
    limit: '-1',
    start: 0,
  },
};
export const MAP_SLUG = '/dealer-locations';
export const MAP_RADIAL_SLUG = '/work-orders/radial';
export const MAP_ITEMS_PER_PAGE = 5;
export const MAP_MAX_SEARCH_RADIUS = 100;
export const DEFAULT_MAP_RADIUS = 10000;
export const DEFAULT_MAP_ZOOM = 5;
export const MAP_INITIAL_STATE = {
  modalMapOpen: false,
  activeMarker: null,
  locations: null,
  zoom: DEFAULT_MAP_ZOOM,
  selectedKey: '',
  address: {
    selected: {
      address: '',
      geometry: {
        lat: 0,
        lng: 0,
      },
    },
  },
  hasLoaded: {
    initialLoad: true,
    partners: false,
    dispatch: false,
  },
  data: {
    dispatch: [],
    partner: [],
    radialData: {
      inside: [],
      outside: [],
    },
  },
  distanceOptions: {
    selected: null,
  },
  mapCenter: {
    geometry: { lat: 36.831095, lng: -88.65829 },
  },
  yourLocation: {
    lat: 0,
    lng: 0,
  },
  yourAddress: {
    address_components: [],
    formatted_address: null,
    from: null,
  },
  currentPage: {
    page: 1,
  },
  ipAddress: null,
  browserLocationBlocked: false,
};

export const CONTACT_DETAILS: {
  countryCode: number;
  email: string;
  supportFormUrl: string;
  phone: number;
  hours: { start: string; end: string };
  days: { open: string[]; closed: string[] };
  timezone: string;
} = {
  countryCode: 1,
  email: 'support@mrcool.com',
  supportFormUrl: 'https://support.mrcool.com/hc/en-us/requests/new',
  phone: 2703660457,
  hours: {
    start: '6:30 AM',
    end: '10:30 PM',
  },
  days: {
    open: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    closed: ['Sat', 'Sun'],
  },
  timezone: 'CST',
};
